import React, { useState } from 'react';
import { FaHome, FaChartBar, FaCalendarAlt, FaPlusCircle, FaSearch, FaUserCircle, FaEnvelope } from 'react-icons/fa';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import {
  Container, Sidebar, SidebarItem, SidebarBottom, MainContent, HeaderContainer, Logo, Button, Input,
  GridContainer, PostCard, PostImage, PostCaption, PostStats, StatItem, InfluencerInfo, ReadMoreButton,
  GraphContainer, TextArea, InfluencerOptions, OptionButton, MessagingSection, WelcomeContainer, WelcomeText, ActionButton, LoginModal, Overlay, CloseButton, Header, LoginForm, MessageIconSection, ChatList, ChatItem, MessageChatSection, ChatMessage, ChatInputSection, SendButton, CloseButtonChat
} from './StyledComponents';  
import { WishListProvider } from './WishListContext';
import { apiLogin, fetchInstagramData } from './api';

const InfluenceApp = () => {
  const [instagramData, setInstagramData] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Tracks user login state
  const [expandedPost, setExpandedPost] = useState(null);
  const [showMessaging, setShowMessaging] = useState(false);
  const [message, setMessage] = useState('');
  const [chats, setChats] = useState([]); // Store chat history
  const [selectedChat, setSelectedChat] = useState(null); // Track selected chat
  const [isMessageSectionOpen, setIsMessageSectionOpen] = useState(false); // To open/close message section
  const [showModal, setShowModal] = useState(false); // To show/hide login modal
  const [hasLoggedIn, setHasLoggedIn] = useState(false); // Track if user has logged in

  // Function to handle user login
  const handleLogin = async () => {
    const token = await apiLogin(email, password);
    if (token) {
      setToken(token);
      setIsLoggedIn(true); // Set login state to true on successful login
      setShowModal(false);  // Hide login modal
      setHasLoggedIn(true);  // Transition to main app
    } else {
      alert('Failed to log in');
    }
  };

  // Function to fetch Instagram data after logging in
  const handleFetchData = async () => {
    if (!username || !token) return;
    const data = await fetchInstagramData(username, token);
    if (data) {
      const formattedData = {
        fullName: data.instagram_full_name,
        followers: data.followers,
        reach: data.posts.reduce((acc, post) => acc + (post.likes || 0), 0),
        posts: data.posts.map(post => ({
          id: post.instagram_id,
          imageUrl: post.media_url,
          description: post.description,
          likes: post.likes,
          comments: post.comments,
        })),
      };
      setInstagramData(formattedData);
    } else {
      alert('Failed to fetch data');
    }
  };

  // Toggle Read More/Read Less for posts with long captions
  const toggleReadMore = (postId) => {
    setExpandedPost(expandedPost === postId ? null : postId);
  };

  // Function to send a message to the influencer and store it in the chat history
  const handleSendMessage = () => {
    if (instagramData && message) {
      const newChat = {
        influencer: instagramData.fullName,
        message,
        timestamp: new Date().toLocaleString(),
      };
      setChats([...chats, newChat]); // Add to chat history
      alert(`Message sent to ${instagramData.fullName}: ${message}`);
      setMessage(''); // Clear message after sending
    }
  };

  // Function to view the chat section
  const handleViewChats = () => {
    setIsMessageSectionOpen(true); // Open chat section
  };

  // Function to cancel the message being written
  const handleCancelMessage = () => {
    setMessage(''); // Clear the message
  };

  // Select a specific chat from the list
  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
  };

  // Show login modal
  const handleLoginClick = () => {
    setShowModal(true);
  };

  // Hide login modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Trigger fetch data using the search bar
  const handleSearch = () => {
    if (isLoggedIn && username) {
      handleFetchData();
    }
  };

  // Handle 'Enter' key press for search
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <WishListProvider>
      {/* Show Welcome Page if the user hasn't logged in yet */}
      {!hasLoggedIn ? (
        <WelcomeContainer>
          <Header>
            <Logo src="/logo.png" alt="InfluenceMe Logo" />  {/* Logo for welcome page */}
            <ActionButton onClick={handleLoginClick}>Login</ActionButton>
          </Header>
          <WelcomeText>Your next big idea starts here</WelcomeText>
          <p>Get started by connecting with the best influencers in the industry.</p>

          {showModal && (
            <>
              <Overlay onClick={handleCloseModal} />
              <LoginModal>
                <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
                <h2>Login</h2>
                <LoginForm>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button onClick={handleLogin}>Login</Button>
                </LoginForm>
              </LoginModal>
            </>
          )}
        </WelcomeContainer>
      ) : (
        <Container>
          <Sidebar>
            <div>
              <SidebarItem>
                <FaHome />
              </SidebarItem>
              <SidebarItem>
                <FaChartBar />
              </SidebarItem>
              <SidebarItem>
                <FaCalendarAlt />
              </SidebarItem>
              <SidebarItem>
                <FaPlusCircle />
              </SidebarItem>
              <SidebarItem>
                <FaSearch />
              </SidebarItem>
              <SidebarItem onClick={handleViewChats}> {/* Message icon to view chats */}
                <FaEnvelope />
              </SidebarItem>
            </div>
            <SidebarBottom>
              <FaUserCircle size={30} />
              <span>Aditya</span>
            </SidebarBottom>
          </Sidebar>

          <MainContent>
            <HeaderContainer>
              <Logo src="/logo.png" alt="Influence Me Logo" />
              {/* Add the search bar and fetch button */}
              {isLoggedIn && (
                <>
                  <Input
                    type="text"
                    placeholder="Search for influencers by Instagram username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyPress={handleKeyPress}  // Handle Enter key for search
                  />
                  <Button onClick={handleSearch}>Search</Button>
                </>
              )}
            </HeaderContainer>

            {/* Messaging Section */}
            {isMessageSectionOpen && (
              <MessageIconSection>
                <h2>Chats with Influencers</h2>
                <ChatList>
                  {chats.map((chat, index) => (
                    <ChatItem key={index} onClick={() => handleSelectChat(chat)}>
                      <p><strong>{chat.influencer}</strong></p>
                      <p>{chat.message}</p>
                      <p>{chat.timestamp}</p>
                    </ChatItem>
                  ))}
                </ChatList>

                {/* Show selected chat details */}
                {selectedChat && (
                  <MessageChatSection>
                    <div className="chat-window">
                      <ChatMessage sentByUser={true}>
                        {selectedChat.message}
                        <span>{selectedChat.timestamp}</span>
                      </ChatMessage>
                    </div>
                    <ChatInputSection>
                      <TextArea
                        placeholder="Reply to this chat..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <SendButton onClick={handleSendMessage}>Send</SendButton>
                      <CloseButtonChat onClick={handleCancelMessage}>Cancel</CloseButtonChat> {/* Cancel button */}
                    </ChatInputSection>
                  </MessageChatSection>
                )}
              </MessageIconSection>
            )}

            {instagramData && (
              <div>
                <InfluencerInfo>
                  <h2>{instagramData.fullName}</h2>
                  <p>Followers: {instagramData.followers}</p>
                  <p>Reach: {instagramData.reach}</p>
                </InfluencerInfo>

                <InfluencerOptions>
                  <OptionButton onClick={() => setShowMessaging(true)}>Message</OptionButton>
                </InfluencerOptions>

                {/* Messaging Section */}
                {showMessaging && (
                  <MessagingSection>
                    <h3>Message {instagramData.fullName}</h3>
                    <TextArea
                      placeholder="Write your message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button onClick={handleSendMessage}>Send Message</Button>
                    <CloseButtonChat onClick={handleCancelMessage}>Cancel</CloseButtonChat> {/* Cancel button */}
                  </MessagingSection>
                )}

                {/* Graph and Posts */}
                <GraphContainer>
                  <h3>Post Stats (Likes and Comments)</h3>
                  <LineChart width={600} height={300} data={instagramData.posts}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="description" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="likes" stroke="#8884d8" />
                    <Line type="monotone" dataKey="comments" stroke="#82ca9d" />
                  </LineChart>
                </GraphContainer>

                <GridContainer>
                  {instagramData.posts.map(post => (
                    <PostCard key={post.id}>
                      <PostImage src={post.imageUrl} alt="Post Image" />
                      <PostCaption>
                        <p>
                          {expandedPost === post.id || post.description.length <= 100
                            ? post.description
                            : `${post.description.substring(0, 100)}...`}
                        </p>
                        {post.description.length > 100 && (
                          <ReadMoreButton onClick={() => toggleReadMore(post.id)}>
                            {expandedPost === post.id ? 'Read Less' : 'Read More'}
                          </ReadMoreButton>
                        )}
                      </PostCaption>
                      <PostStats>
                        <StatItem>Likes: {post.likes}</StatItem>
                        <StatItem>Comments: {post.comments}</StatItem>
                      </PostStats>
                    </PostCard>
                  ))}
                </GridContainer>
              </div>
            )}
          </MainContent>
        </Container>
      )}
    </WishListProvider>
  );
};

export default InfluenceApp;

